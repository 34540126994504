import React, { useEffect, useState } from "react";
import "../style/Blog.css";
import { Box, Image, Text } from "@chakra-ui/react";
import '../style/body_1.css';
import List from "../component/list.component";
import addressSerivice from "../service/address.serivice";
import { useSelector } from "react-redux";
import { selectUserProfile } from "../reducers";


const AddressList = () => {

  const [addressList, setAddressList] = useState(null);
  const userProfile = useSelector(selectUserProfile);

  const fetchAddressList = async () => {
    try {
      const data = await addressSerivice.fetchAllAddress(userProfile.user_id);
      console.log(data);
      setAddressList(data);
    } catch (err) {
      throw new Error(`Error in fetching address list from backend`+err);
    }
  }

  useEffect(() => {
    console.log(userProfile);
    fetchAddressList();
  }, []);


  return (
    <div>
    <Box borderRadius={'md'} padding={'4'}  backgroundColor={'gray.200'} >
      <Text fontWeight={'semibold'} textColor={'black'} fontSize={{base: 'sm', md : 'md', lg:"xl", xl: "xl"}}>Saved Address</Text>
    </Box>
    <Box bg="gray.100" p={4} minHeight={{ base: '48vh', sm: '50vh', md: '60vh', lg: '60vh', xl: '60vh' }}>
      {addressList && addressList.map((address, index) => (
        <List key={index} address={address} />
      ))}
    </Box>
    </div>
  );
};

export default AddressList;
