import React, { useEffect, useState } from "react";
import "../style/Blog.css";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import '../style/body_1.css';
import Blog from "./blog.pages";
import FlippableCard from "../component/flippableCard.component";
import Carousel from "better-react-carousel";
import offeringService from "../service/offering.service";



const Pass = () => {

  const [passesList, setPassesList] = useState([]);

  const fetchPassesList = async () => {
    try {

      const result = await offeringService.fetchPlanList('Daily Pass');
      if(!result){
        throw new Error('Error in fetching passes list from backend');
      }
      setPassesList(result);
      console.log(passesList);
    } catch (err) {
      throw new Error('Error in fetching plan list' + err);
    }
  }

  useEffect(() => {
    fetchPassesList();
  },[])

  return (

    <Box
      w="100%" 
      m={{ base: '4% 8%', md: '4% 8%' }} 
      minHeight={{ base: '48vh', sm: '50vh', md: '60vh', lg: '60vh', xl: '60vh' }}
      display="grid"
      gridTemplateColumns="repeat(3, 1fr)"
      gap={4}
      justifyContent="center"
      alignItems="center"
      px={{ base: 4, md: 8 }}
    >
      {passesList.map((item, index) => (
        <FlippableCard data={item} />
      ))}
    </Box>
  );
};

export default Pass;
