import React, { useEffect, useState } from "react";
import "../style/Blog.css";
import { Box, Image, Text } from "@chakra-ui/react";
import '../style/body_1.css';
import Activity from "../component/activity.component";
import activityService from "../service/activity.service";
import { useSelector } from "react-redux";
import { selectUserProfile } from "../reducers";
import { useNavigate } from "react-router-dom";


const ActivityList = () => {

  const [activityList, setActivityList] = useState([]);
  const userProfile = useSelector(selectUserProfile);
  const navigate = useNavigate();

  const fetchActivityList = async () => {
    try {
        const result = await activityService.fetchActivityList(userProfile.user_id);
        console.log(result);
        setActivityList(result);
    }catch(e) {
        console.error(`Some thing went wrong !\n` + e);
        alert(`Some thing went wrong !`);
    }
  }

  const handleOnClick = (activity) => {
      navigate('/activity_details', {
        state: {
          activity: activity
        }
      });
  }

  useEffect(() => {
    console.log('hell');
    console.log(userProfile);
    fetchActivityList();
  },[]);

  return (
    <Box bg="gray.100" p={4} minHeight={{ base: '48vh', sm: '50vh', md: '60vh', lg: '60vh', xl: '60vh' }}>
      {activityList && activityList.map((activity, index) => (
        <Box
          cursor="pointer"
          opacity={1}
          _hover={{ opacity: 0.5 }}
          onClick={() => handleOnClick(activity)}
          borderRadius="md"
          padding="4"
          borderColor="gray.300"
          height="100%"
          overflow="hidden"
        >
          <Activity key={index} data={activity} />
        </Box>
      ))}
    </Box>
  );
};

export default ActivityList;
