import React, { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react';
import BodyOne from '../component/body_1.component';
import BodyTwo from '../component/body_2.component';
import BodyThree from '../component/body_three.component';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsLoggedIn, selectPendingProfile, selectUserProfile, setUserProfile } from '../reducers';
import { useNavigate } from 'react-router-dom';
import profileService from '../service/profile.service';

export default function Landingpages() {
  const userProfile = useSelector(selectUserProfile);
  const logIn = useSelector(selectIsLoggedIn)
  const user_id = userProfile?.user_id;
  const dispatch = useDispatch();

  const fetchData = async () => {
    try{
      const response = await profileService.fetchProfileDetails(user_id);
      if(!response) throw new Error('user not found');
      console.log(response);
      dispatch(setUserProfile({
        ...userProfile,
        email: response.user_email_id,
        user_phone: response.user_phone_no,
        user_name: response.user_name
      }))
    } catch(err) {
      throw new Error('Error in fetching Details from backend')
    }
  }

  useEffect(() => {
    if(logIn)fetchData();
    console.log(userProfile);
    console.log(logIn);
    }, []);


  return (
    <Box  maxWidth={'100%'} padding={0} margin={0}
    >
      <BodyOne />
      <BodyThree />
      <BodyTwo />
    </Box>
  )
}
