import React, { useState } from 'react';
import { Button, Image, Text, VStack } from '@chakra-ui/react';
import { useLocation, useNavigate } from "react-router-dom";


const checkoutQR='https://res.cloudinary.com/dn0hjcpmq/image/upload/v1733043573/WhatsApp_Image_2024-12-01_at_14.29.02_rbfubo.jpg';




const CheckoutPage = () => {
  const location = useLocation();
  const receivedProps = location.state;

  const navigate=useNavigate()
  const [selectedItem, setSelectedItem] = useState(null);
  const [enableTop, setEnableTop] = useState(true);

  const handleOnClick = (item) => {
    setSelectedItem(item);
    setEnableTop(false);
  };

  const hadleCheckout = () => {
    console.log(receivedProps);
    navigate('/payment_confirmation');
  }

  return (
  <VStack
    spacing={4}
    maxH='100%'
    justifyContent='center'
    alignItems='center'
    style={{ width: '100%', height: '50%' }}
  >
    <Image src={checkoutQR} alt="Payment QR" width={'40%'} paddingBottom='-4'/>
    <Text padding='3' fontWeight='bold'>We're currently setting up our online payment system. In the meantime, please use the QR code below to make a payment of ₹{receivedProps?.price}</Text>
    
    <Button 
        backgroundColor="black" 
        color="white"
        size="lg" 
        borderRadius="md" 
        padding="6"
        margin='4' 
        marginTop='2'
        width="80%"
        onClick={() => hadleCheckout()}
    >
        Confirm Payment
    </Button>
  </VStack>
  );
};

export default CheckoutPage;



/**
 * 
      <Button 
        backgroundColor="black" 
        color="white"
        size="lg" 
        borderRadius="md" 
        padding="4" 
        width="100%"
        onClick={() => console.log('Checkout clicked')}
      >
        Proceed to Checkout
      </Button>
 */